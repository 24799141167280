<template>
  <div class="container cs-container">
    <div ref="documentPrint" id="documentPrint" class="h-full">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-6 text-center font-weight-bold"></div>
        <div class="col-4 d-flex justify-content-end cs-text-bold">
          <span>{{ $t("lbl_bill_code") }}:</span>
          <span class="ml-1">{{ receiptData.pharma_receipt_code }}</span>
        </div>
      </div>
      <div class="text-center mt-2">
        <h2 class="text-uppercase">{{ $t("lbl_receipt_bill") }}</h2>
        <h4 v-if="receiptData.inventory_info" class="m-0">
          {{ receiptData.inventory_info.name }}
        </h4>
      </div>
      <div class="row mt-2">
        <div class="col-6">
          <span>{{ $t("lbl_full_name") }}:</span>
          <span class="ml-1 cs-text-bold">{{ receiptData.customer_name }}</span>
        </div>
        <div class="col-3">
          <span>{{ $t("lbl_age_short") }}:</span>
          <span class="ml-1 cs-text-bold">{{
            receiptData.customer_age || ""
          }}</span>
        </div>
        <div class="col-3">
          <span>{{ $t("lbl_gender") }}:</span>
          <span class="ml-1 cs-text-bold">{{
            $t(receiptData.customer_gender === 1 ? "lbl_male" : "lbl_female")
          }}</span>
        </div>
      </div>
      <div class>
        <span>{{ $t("lbl_phone") }}:</span>
        <span class="ml-1 cs-text-bold">{{ receiptData.customer_phone }}</span>
      </div>
      <div class>
        <span>{{ $t("lbl_address") }}:</span>
        <span class="ml-1 cs-text-bold">{{
          receiptData.customer_address
        }}</span>
      </div>

      <b-overlay
        :show="false"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          small
          striped
          bordered
          outlined
          class="cs-btable mt-2"
          responsive
          :items="receiptData.receiptList"
          :fields="tableColumns"
        >
          <template #cell(index)="data">
            <span class="text-capitalize">{{ data.index + 1 }}</span>
          </template>
          <template #cell(unit_id)="data">
            <span class="text-capitalize">{{ getUnit(data.item) }}</span>
          </template>
          <template #cell(price)="data">
            <span class="text-capitalize">{{
              appUtils.numberFormat(data.item.realPrice)
            }}</span>
          </template>
          <template #cell(total_price)="data">
            <span class="text-capitalize">{{
              appUtils.numberFormat(data.item.total_price)
            }}</span>
          </template>
          <template #bottom-row>
            <b-th colspan="6" class="text-right" style="font-weight: 500">{{
              $t("lbl_total_price")
            }}</b-th>
            <b-th class="text-right" style="font-weight: 500">{{
              appUtils.numberFormat(receiptData.total_amount)
            }}</b-th>
          </template>
        </b-table>
      </b-overlay>
      <div class="cs-text-bold mt-3">
        <div>
          <span>- {{ $t("lbl_total_price") }}:</span>
          <span v-if="receiptData.total_amount" class="ml-1">{{
            formatPrice(receiptData.total_amount)
          }}</span>
        </div>
        <div class="d-flex align-items-center">
          <span>- {{ $t("lbl_total_price_text") }}:</span>
          <span v-if="receiptData.total_amount" class="ml-1 cs-capitalize">
            <p class="m-0">
              {{ `${appUtils.numberToWords(receiptData.total_amount)}` }}
            </p>
          </span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4"></div>
        <div class="col-4"></div>
        <div class="col-4 text-center">
          <div class="italic">
            <i>{{ formatDate(receiptData.receipt_date) }}</i>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-4 text-center">
          <div>
            <div class="cs-text-bold">{{ $t("lbl_receiving_by_shot") }}</div>
            <div>
              <i>{{ $t("lbl_sign_name") }}</i>
            </div>
            <div
              v-if="receiptData.customer_name"
              style="margin-top: 65px"
              class="text-uppercase"
            >
              {{ receiptData.customer_name }}
            </div>
          </div>
        </div>
        <div class="col-4 text-center"></div>
        <div class="col-4 text-center">
          <div>
            <div class="cs-text-bold">{{ $t("lbl_cashier") }}</div>
            <div class="text-center">
              <i>{{ $t("lbl_sign_name") }}</i>
            </div>
            <div
              class="text-uppercase"
              v-if="receiptData.collect_user"
              style="margin-top: 65px"
            >
              {{ receiptData.collect_user.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/libs/i18n";
import appUtils from "@/utils/appUtils";

const tableColumns = [
  { key: "index", label: "STT", sortable: false },
  {
    key: "product_info.name",
    label: i18n.t("lbl_product_name_short"),
    sortable: false,
  },
  {
    key: "qty",
    label: i18n.t("lbl_amount"),

    sortable: false,
    class: "text-center",
  },
  { key: "unit_id", label: i18n.t("lbl_units"), sortable: false },
  {
    key: "price",
    label: i18n.t("lbl_price_vat"),
    class: "text-right",
  },
  {
    key: "total_price",
    label: i18n.t("lbl_end_price"),
    sortable: false,
    class: "text-right",
  },
];

export default {
  name: "ReceiptPrint",
  props: {
    receiptData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      appUtils,
      tableColumns,
    };
  },
  methods: {
    formatDate(timestamp) {
      return window.moment(timestamp).format("HH:mm-DD/MM/YYYY");
    },
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    getUnit(data) {
      return data?.product_info?.unit_info?.name || "";
    },
  },
};
</script>
<style lang="scss" scoped>
.cs-container {
  position: relative;
  background-color: #fff;
  min-height: calc(100vh - 1px);
  max-width: 1000px;
  overflow: hidden;
}

.cs-container * {
  color: #000;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;

  h2 {
    font-weight: 600;
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .cs-table {
    width: 1000px;
    margin-top: 16px;
    .w-6 {
      width: 6%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-15 {
      width: 15%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-8 {
      width: 8%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-10 {
      width: 10%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-12 {
      width: 12%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-13 {
      width: 13%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-16 {
      width: 16%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-18 {
      width: 18%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-20 {
      width: 20%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-90 {
      width: 85%;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .table-item {
      border-top: unset;
    }

    .body-item {
      padding-top: 8px;
      padding-bottom: 8px;
      // border-right: 1px solid #787878;

      box-sizing: border-box;
    }
  }
  .cs-table * {
    font-size: 16px;
  }

  .border-t {
    border-top: 1px solid #787878;
  }
  .border-b {
    border-bottom: 1px solid #787878;
  }
  .border-l {
    border-left: 1px solid #787878;
  }
  .border-r {
    border-right: 1px solid #787878;
  }
  .cs-border {
    border: 1px solid #787878;
  }
  .cs-capitalize {
    text-transform: lowercase;

    p::first-letter {
      text-transform: capitalize;
    }
  }
}
.cs-relative {
  position: relative;
}

.cs-text-bold {
  font-weight: 600;
}

.cs-close {
  display: block;
  position: absolute;
  top: -50px;
  right: 40px;
  // height: 30px;
  // width: 30px;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
}

.cs-font * {
  font-size: 16px;

  h4 {
    font-size: 20px;
  }
}

.not-payment ::v-deep {
  background-color: rgb(243, 129, 8) !important;
  color: white !important;
}

.reject ::v-deep {
  color: white !important;
  background-color: red !important;
}

.payment ::v-deep {
  color: white !important;
  background-color: #17ad73 !important;
}

.status {
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  font-size: 16px;
  user-select: none;
  width: 100%;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.menu-select {
  background-color: #fff;
  position: absolute;
  bottom: -130px;
  right: 0;
  z-index: 2;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cs-menu-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;

  &:hover {
    background-color: rgba(21, 76, 205, 0.1);
  }

  span {
    display: block;
    // width: 100%;
    // height: 100%;
  }
}

.cs-item-active {
  background-color: #20419b !important;
  color: white;
}
::v-deep .cs-btable * {
  color: #000;
  font-family: "Times New Roman", Times, serif;

  thead * {
    font-size: 20px;
    text-transform: none !important;
  }

  tbody {
    font-size: 20px;
  }
}
.cs-print {
  margin: 0 !important;
}

.cs-print * {
  font-size: 20px;
}

@media print {
  .cs-print {
    display: block !important;
    overflow: hidden;
    margin-top: 30px;

    .card-body {
      padding: 0 !important;
    }
  }
}
</style>

<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card class="cs-print">
      <div class="d-print-none d-flex justify-content-end align-items-center mr-1 mb-2 gap-4">
        <b-button @click="handlePrint" variant="primary">{{ $t('lbl_print_bill') }}</b-button>
        <span @click="handleClose" class="cursor-pointer" style="font-size:40px">&times;</span>
      </div>
      <ReceiptPrint :receiptData="receiptData" />
    </b-card>
  </b-overlay>
</template>

<script>
import appUtils from "@/utils/appUtils";
import ReceiptPrint from "./ReceiptPrint.vue";

export default {
  name: "ReceiptDetail",
  components: {
    ReceiptPrint
  },
  data() {
    return {
      receiptData: {},
      isLoading: false,
      tableColumns,
      appUtils
    };
  },
  computed: {
    userLocal() {
      return JSON.parse(localStorage.getItem("user"));
    }
  },
  watch: {
    $route() {
      this.handleViewDetail();
    }
  },
  async created() {
    await this.handleViewDetail();
  },
  methods: {
    async handleViewDetail() {},
    formatDate(timestamp) {
      return window.moment(timestamp).format("HH:mm-DD/MM/YYYY");
    },
    handleClose() {
      this.$router.replace({ name: "ReceiptManager" });
    },
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    handlePrint() {
      window.print();
    },
    getUnit(data) {
      return data?.product_info?.unit_info?.name || "";
    }
  },
  components: { ReceiptPrint }
};
</script>

<style lang="scss" scoped>
.cs-container {
  position: relative;
  background-color: #fff;
  min-height: calc(100vh - 1px);
  max-width: 1000px;
  overflow: hidden;
}

.cs-container * {
  color: #000;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;

  h2 {
    font-weight: 600;
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .cs-table {
    width: 1000px;
    margin-top: 16px;
    .w-6 {
      width: 6%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-15 {
      width: 15%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-8 {
      width: 8%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-10 {
      width: 10%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-12 {
      width: 12%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-13 {
      width: 13%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-16 {
      width: 16%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-18 {
      width: 18%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-20 {
      width: 20%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-90 {
      width: 85%;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .table-item {
      border-top: unset;
    }

    .body-item {
      padding-top: 8px;
      padding-bottom: 8px;
      // border-right: 1px solid #787878;

      box-sizing: border-box;
    }
  }
  .cs-table * {
    font-size: 16px;
  }

  .border-t {
    border-top: 1px solid #787878;
  }
  .border-b {
    border-bottom: 1px solid #787878;
  }
  .border-l {
    border-left: 1px solid #787878;
  }
  .border-r {
    border-right: 1px solid #787878;
  }
  .cs-border {
    border: 1px solid #787878;
  }
  .cs-capitalize {
    text-transform: lowercase;

    p::first-letter {
      text-transform: capitalize;
    }
  }
}
.cs-relative {
  position: relative;
}

.cs-text-bold {
  font-weight: 600;
}

.cs-close {
  display: block;
  position: absolute;
  top: -50px;
  right: 40px;
  // height: 30px;
  // width: 30px;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
}

.cs-font * {
  font-size: 16px;

  h4 {
    font-size: 20px;
  }
}

.not-payment ::v-deep {
  background-color: rgb(243, 129, 8) !important;
  color: white !important;
}

.reject ::v-deep {
  color: white !important;
  background-color: red !important;
}

.payment ::v-deep {
  color: white !important;
  background-color: #17ad73 !important;
}

.status {
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  font-size: 16px;
  user-select: none;
  width: 100%;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.menu-select {
  background-color: #fff;
  position: absolute;
  bottom: -130px;
  right: 0;
  z-index: 2;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cs-menu-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;

  &:hover {
    background-color: rgba(21, 76, 205, 0.1);
  }

  span {
    display: block;
    // width: 100%;
    // height: 100%;
  }
}

.cs-item-active {
  background-color: #20419b !important;
  color: white;
}
::v-deep .cs-btable * {
  color: #000;
  font-family: "Times New Roman", Times, serif;

  thead * {
    font-size: 20px;
    text-transform: none !important;
  }

  tbody {
    font-size: 20px;
  }
}
.cs-print {
  margin: 0 !important;
}

.cs-print * {
  font-size: 20px;
}

@media print {
  .cs-print {
    display: block !important;
    overflow: hidden;
    margin-top: 30px;

    .card-body {
      padding: 0 !important;
    }
  }
}
</style>